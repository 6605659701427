var createTabNav = function (idSuffix,useFilter,useButton) {
    var $el, leftPos, newWidth, idSuffix = idSuffix, useFilter = useFilter,
        $mainNav = $('#afs-tab-nav-'+idSuffix);
    if(useButton) {
        var $ctaButton = $('.afs-'+idSuffix+' .afs-cta-button');
    }

    $mainNav.append('<li id="magic-line-wrapper-'+idSuffix+'"></li>');
    $mainNav.append('<li id="magic-line-'+idSuffix+'"></li>');
    var $magicLine = $('#magic-line-'+idSuffix);
    var $magicLineWrapper = $('#magic-line-wrapper-'+idSuffix);
    var $navItems = $('#afs-tab-nav-'+idSuffix+' .nav-item');

    function setMagicLine() {
        var totalWidth = 0;
        var PADDING_BETWEEN_NAV_ITEMS = 12;

        var $activeTab = $('#afs-tab-nav-'+idSuffix+' .active-tab a');
        $magicLine
            .width($activeTab.width())
            .css('left', $activeTab.position().left)
            .data('origLeft', $magicLine.position().left)
            .data('origWidth', $magicLine.width());

        $('#afs-tab-nav-'+idSuffix+' li a').hover(function() {
            $el = $(this);
            leftPos = $el.position().left;
            newWidth = $el.width();
            $magicLine.stop().animate({
                left: leftPos,
                width: newWidth
            });
            $magicLine.css('background-color','#0779B4');
        }, function() {
            $magicLine.stop().animate({
                left: $magicLine.data('origLeft'),
                width: $magicLine.data('origWidth')
            });
            $magicLine.css('background-color','#002749');
        });

        $.each($navItems, function (index, currentNavItem) {
            var $currentNavItem = $(currentNavItem);
            var currentWidth = $currentNavItem.width() + (index === 0 || index === $navItems.length - 1 ? PADDING_BETWEEN_NAV_ITEMS / 2 : PADDING_BETWEEN_NAV_ITEMS);
            totalWidth += currentWidth;
        });
        $magicLineWrapper.width(totalWidth);
        $mainNav.width(totalWidth);
    }

    setMagicLine();

    var $mainNavLi = $mainNav.find('.nav-item');
    var $filterBlocks = $('.afs-'+idSuffix+' .filter-block');

    $.each($mainNavLi, function (index, currentLi){
        var $currentLi = $(currentLi);
        $currentLi.on('click', function (e) {
            e.preventDefault();
            if(!$currentLi.hasClass('active-tab')) {
                $currentLi.siblings().removeClass('active-tab');
                $currentLi.addClass('active-tab');
                var currentDataId = $currentLi.attr('data-id');
                var $filterById = $('#'+currentDataId);
                if(useButton) {
                    var ctaButtonHref = $ctaButton.attr('href');
                    var ctaButtonHrefLength = ctaButtonHref.length;
                    var startIndex;
                    if (ctaButtonHref.indexOf('?type=') !== -1) {
                        startIndex = ctaButtonHref.indexOf('?type=') + 6;
                    } else if (ctaButtonHref.indexOf('&type=') !== -1) {
                        startIndex = ctaButtonHref.indexOf('&type=') + 6;
                    } else {
                        startIndex = ctaButtonHrefLength;
                    }
                    if (startIndex < ctaButtonHrefLength) {
                        var typeTillEnd = ctaButtonHref.substr(startIndex,ctaButtonHrefLength-1);
                        var justType = typeTillEnd;
                        if (typeTillEnd.indexOf('&') !== -1) {
                            justType = typeTillEnd.substr(0,typeTillEnd.indexOf('&'));
                        }
                        $ctaButton.attr('href',ctaButtonHref.replace(justType,currentDataId));
                    } else {
                        var paramSuffix = (ctaButtonHref.indexOf('?') !== -1) ? '&type=' : '?type=';
                        $ctaButton.attr('href',ctaButtonHref + paramSuffix + currentDataId);
                    }
                }
                $filterBlocks.hide();
                $filterById.show();
                setMagicLine();
            }
            return false;
        });
    });
};